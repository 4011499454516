import { isSameMonth, isSameYear } from '@awork/_shared/functions/date-fns-wrappers'
import { IRetainerBudget, RetainerBudget } from './retainer-budget.model'

export interface IRetainer {
  id: string
  startDate: Date | string
  endDate?: Date | string
  timeBudget: number
  retainerPeriod: string
  timezone: string
  rollOverUnderspentBudget: boolean
  rollOverOverspentBudget: boolean
  projectId: string
  isExternal: boolean
  retainerBudgets: IRetainerBudget[]
  createdOn: Date | string
  createdBy: string
  updatedOn: Date | string
  updatedBy: string
  syncProjectDates: boolean
}

export class Retainer implements IRetainer {
  id: string
  startDate: Date | string
  endDate?: Date | string
  timeBudget: number
  retainerPeriod: string //set manually
  timezone: string //set manually
  rollOverUnderspentBudget: boolean
  rollOverOverspentBudget: boolean
  projectId: string
  isExternal: boolean
  retainerBudgets: RetainerBudget[]
  createdOn: Date | string
  createdBy: string
  updatedOn: Date | string
  updatedBy: string
  syncProjectDates: boolean

  constructor(init?: Partial<IRetainer>) {
    Object.assign(this, init)
    this.timezone = 'Etc/UTC'
    this.retainerPeriod = 'monthly'
    this.convertDatesToUTC()

    if (this.retainerBudgets?.length) {
      this.retainerBudgets = this.retainerBudgets.map(budget => new RetainerBudget(budget))
    }
  }

  /**
   * Checks whether the current date falls within the date range
   * of the retainer (startDate and endDate) to determine if this is the current retainer
   * @returns {boolean}
   */
  isCurrentRetainer(): boolean {
    const today = new Date()
    const start = new Date(this.startDate)

    return today >= start && isSameYear(today, start) && isSameMonth(today, start)
  }

  /**
   * Converts all the dates to UTC
   */
  convertDatesToUTC(): void {
    if (this.startDate) {
      this.startDate = this.convertToUTC(this.startDate)
    }

    if (this.endDate) {
      this.endDate = this.convertToUTC(this.endDate)
    }
  }

  /**
   * Returns the total tracked duration on the retainer
   * @returns {number}
   */
  totalTrackedDuration(): number {
    return this.retainerBudgets.reduce((total, budget) => total + budget.trackedDuration, 0)
  }

  /**
   * Converts a given date or date string to a UTC date object.
   * @param {Date | string} date
   * @returns {Date}
   * @private
   */
  private convertToUTC(date: Date | string): Date {
    const d = new Date(date)
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()))
  }
}
